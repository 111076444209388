<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="visibleBreadcrumbs" disable-button />
    <div class="row">
      <div class="col-12">
        <v-card class="rounded-card" flat outlined>
          <v-card-text class="mt-5 mb-3 px-12">
            <app-form ref="form" :fields="fields"
              :submitButton="submitButton"
              @date-changed="dateChanged"
              @submitted="handleSubmit"
              disableCancelButton
            />
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment';
import {mapActions, mapGetters} from 'vuex';
import {permissionsList} from 'constants';

export default {
  name: 'GoalForm',
  data() {
    return {
      previousRoute: null,
      isEditMode: false,
      isLoaded: false,
      submitButton: {
        label: this.$t('actions.submit')
      },
      fields: {
        assignee: {
          type: 'select',
          key: 'assigneeId',
          value: '',
          options: [],
          disabled: false,
          label: this.$t('models.goal.attributes.assignee'),
          trackable: true,
          itemText: 'text',
          itemValue: 'key',
          classes: 'col-lg-6 col-md-6 py-0',
          serverErrors: []
        },
        title: {
          type: 'text',
          key: 'title',
          value: '',
          label: this.$t('models.goal.attributes.title'),
          rules: [{name: 'required'}],
          classes: 'col-lg-6 col-md-6 py-0',
          serverErrors: []
        },
        description: {
          type: 'textarea',
          key: 'description',
          value: '',
          label: this.$t('models.goal.attributes.description'),
          classes: 'col-12 py-0',
          serverErrors: []
        },
        startDate: {
          type: 'datepicker',
          key: 'startDate',
          value: '',
          placeholder: this.$t('models.goal.attributes.startDate'),
          label: this.$t('models.goal.attributes.startDate'),
          outlined: true,
          trackable: true,
          dense: true,
          rules: [{name: 'required'}],
          classes: 'col-lg-6 col-md-6 py-0',
          maxDate: '',
          serverErrors: []
        },
        endDate: {
          type: 'datepicker',
          key: 'endDate',
          value: '',
          label: this.$t('models.goal.attributes.endDate'),
          placeholder: this.$t('models.goal.attributes.endDate'),
          rules: [{name: 'required'}],
          trackable: true,
          classes: 'col-lg-6 col-md-6 py-0',
          minDate: '',
          serverErrors: []
        },
        duration: {
          type: 'text',
          key: 'duration',
          value: '',
          hint: this.$t('messages.hints.jiraConventions'),
          label: this.$t('models.goal.attributes.duration'),
          rules: [{name: 'required'}],
          classes: 'col-lg-6 col-md-6 py-0',
          serverErrors: []
        },
        isPrivate: {
          type: 'checkbox',
          key: 'isPrivate',
          value: false,
          label: this.$t('models.goal.attributes.isPrivate'),
          classes: 'mt-n3 col-6 py-0',
          serverErrors: []
        }
      }
    };
  },
  computed: {
    ...mapGetters(['users', 'currentUser', 'isAdmin', 'goal']),
    title() {
      return {
        text: this.isEditMode ? this.$t('views.goals.updateGoal') : this.$t('views.goals.newGoal'),
        icon: 'mdi-clipboard-list-outline'
      };
    },
    breadcrumbs() {
      return [{
        text: this.$t('layout.mainMenu.home'),
        to: {name: 'Home'},
        show: true
      },
      {
        text: this.$t('layout.mainMenu.goals'),
        to: this.goalsPath,
        show: true
      },
      {
        text: this.$route.params.id,
        show: this.isEditMode,
        disabled: true
      },
      {
        text: this.isEditMode ? this.$t('views.goals.edit') : this.$t('views.goals.new'),
        to: this.isEditMode ? {name: 'EditGoal'} : {name: 'NewGoal'},
        exact: true,
        show: true
      }];
    },
    goalsPath() {
      if (this.isAdmin || this.fields.assignee.value === this.currentUser.id) {
        return {
          name: 'UserGoals',
          params: {uuid: this.fields.assignee.value || this.currentUser.id}
        };
      } else {
        return {name: 'GoalRequests'};
      }
    },
    visibleBreadcrumbs() {
      return this.breadcrumbs.filter((breadcrumb) => breadcrumb.show);
    }
  },
  methods: {
    ...mapActions(['getUsers', 'createGoal', 'updateGoal', 'getGoal', 'setPermission']),
    handleSubmit(data) {
      const request = this.isEditMode ? this.updateGoal({goalId: this.$route.params.id, form: data}) :
        this.createGoal(data);

      request.then((response) => {
        if (this.successCode(response.status)) {
          this.goToUserGoals(this.fields.assignee.value);
        } else {
          this.displayInlineServerErrors(this.fields, response.errors);
        }
      });
    },
    dateChanged($event) {
      if ($event.key === 'startDate') {
        this.fields.endDate.minDate = $event.value;
      } else {
        this.fields.startDate.maxDate = $event.value;
      }
    },
    goToUserGoals(assigneeId) {
      this.$router.push({
        name: 'UserGoals',
        params: {uuid: assigneeId === this.currentUser.id ? 'me' : assigneeId}
      });
    },
    setDefaultValues() {
      this.setPermission(permissionsList['MANAGE_GOALS']);
      const promises = [this.getUsers({fields: ['firstName', 'lastName'], sortBy: ['firstName']})];
      if (this.isEditMode) {
        promises.push(this.getGoal(this.$route.params.id));
      }
      Promise.all(promises).then(() => {
        this.fields.assignee.options = this._.map(this.users.items, (user) => ({key: `${user.id}`, text: user.name}));
        this.fields.assignee.disabled = this.isEditMode || this.users.count <= 1;
        this.fields.assignee.value = this.isEditMode ? this.goal.assignee.id : this.currentUser.id;
        this.fields.endDate.minDate = this.isEditMode ? moment(this.goal.startDate, 'DD MMM YYYY').format('YYYY-MM-DD') :
          moment().format('YYYY-MM-DD');

        if (this.isEditMode) {
          ['title', 'description', 'isPrivate'].forEach((field) => this.fields[field].value = this.goal[field]);
          ['startDate', 'endDate'].forEach((field) => {
            this.fields[field].value = moment(this.goal[field], 'DD MMM YYYY').format('YYYY-MM-DD');
          });
          this.fields.duration.value = this.goal.duration.convention;
        } else {
          ['title', 'description', 'isPrivate', 'endDate', 'duration'].forEach((field) => this.fields[field].value = '');
          this.fields.startDate.value = moment().format('YYYY-MM-DD');
          this.$refs.form.resetValidation();
        }
      });
    }
  },
  watch: {
    '$route.params.id': {
      handler: function(id) {
        this.isEditMode = !!id;
        this.setDefaultValues();
      },
      immediate: true
    }
  },
  destroyed() {
    this.setPermission();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from;
    });
  }
};
</script>
